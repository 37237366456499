<template>
  <div class="HeaderView">
    <div class="h_top">
      <div class="ht_left">
        <img
          src="../../assets/img/logo.png"
          @click="getUrl('../Home')"
        />
        <div class="htl_Box">
          <div
            :class="['htlb_title',path=='/Home'?'htlb_click_title':'']"
            @click="getUrl('../Home')"
          >首页</div>
          <div
            :class="['htlb_title',path=='/about'?'htlb_click_title':'']"
            @click="getUrl('../about')"
          >关于米悦</div>
          <div
            :class="['htlb_title',path=='/Bestselling'?'htlb_click_title':'']"
            @click="getUrl('../Bestselling')"
          >畅销展示</div>
          <div
            :class="['htlb_title',path=='/Headlines'?'htlb_click_title':'']"
            @click="getUrl('../Headlines')"
          >米悦头条</div>
          <div
            :class="['htlb_title',path=='/join'?'htlb_click_title':'']"
            @click="getUrl('../join')"
          >加盟咨询</div>
        </div>
      </div>
      <div class="ht_right">
        合作热线 <div class="htr_span1"></div> <span class="htr_span2">400-886-6367</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // titles:{}
      path: ""
    };
  },
  components: {},
  mounted() {},
  watch: {
    $route: {
      handler: function(val, oldVal) {
        console.log("xin", val);
        this.path = val.path;
        // this.titles = val.meta.title;
        // console.log(this.titles.toString());
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },

  methods: {
    getUrl(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss"  scoped >
.HeaderView {
  width: 100vw;
  height: 8rem;
  background-image: url("../../assets/img/miyue_header.png");
  background-size: 100%;
  .h_top {
    margin: 0 auto;
    width: 120rem;
    height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ht_left {
      display: flex;
      align-items: center;
      img {
        width: 6.6rem;
        height: 6rem;
        margin-right: 10rem;
      }
      .htl_Box {
        width: 49.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .htlb_title {
          font-size: 1.6rem;
          color: #333333;
          font-weight: 300;
        }
        .htlb_title:hover {
          color: #e30920;
          font-weight: 500;
        }
        .htlb_click_title {
          color: #e30920;
          font-weight: 500;
        }
      }
    }
    .ht_right {
      font-size: 1.6rem;
      color: #333333;
      display: flex;
      font-weight: 300;
      align-items: center;
      .htr_span1 {
        width: 0.1rem;
        height: 3.6rem;
        background: #000000;
        transform: rotate(15deg);
        margin: 0 1rem;
      }
      .htr_span2 {
        font-size: 2rem;
        font-weight: bold;
        color: #e30920;
      }
    }
  }
}

//媒体查询
@media screen and (max-width: 1200px) {
  .htlb_title {
    font-size: 3rem !important;
  }
  .HeaderView {
    height: 12rem !important;
  }
  .h_top {
    width: 100vw !important;
    height: 12rem !important;
    padding: 0 2rem !important;
    .ht_left {
      img {
        display: none;
      }
      .htl_Box {
        width: 100vw !important;
        justify-content: space-around !important;
      }
    }

    .ht_right {
      display: none !important;
    }
  }
}
</style>
