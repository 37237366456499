<template>
    <div class="layout">
        <HeaderView style="position: fixed;top:0;z-index:99"></HeaderView>
        <div class="layout_right">
            <ContentView style="height:100%"></ContentView>
        </div>
    </div>
</template>

<script>
// import NavBar from "./NavBar";
import HeaderView from "./HeaderView";
import ContentView from "./ContentView";
export default {
  data() {
    return {};
  },
  components: {
    // NavBar,
    HeaderView,
    ContentView
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss"  scoped >
//媒体查询
@media screen and (max-width: 1200px) {
  .layout {
    padding-top: 12rem !important;
  }
}
.layout {
  padding-top: 8rem;
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
}
.layout_right {
  flex: 1;
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}
</style>
